html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-default);
  font-size: 62.5%;
}

button,
span,
select,
option {
  font-family: inherit;
}

:root {
  --font-family-default: Inter;

  --shadow-5: 0px 5px 20px rgba(105, 105, 105, 0.12);
  --error-bg: rgba(245, 0, 50, 0.12);
  --blue-bg: rgba(17, 147, 240, 0.12);
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.SectionMessage {
  /* border: 0px !important */
}

.epr-emoji-native,
.epr-emoji-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  src: url(/fonts/Inter-Black.ttf);
  font-weight: 900;
}
